h3{
	font-size: 1.35em;
}

.App {
  text-align: center;
  display: flex;  
  font-family: Poppins, Helvetica, "sans-serif";
  background-color: white;
}

.sidebar {
	width: 12em;
	height: 100vh;
	background-color: #1e1e2d;
	position: fixed;
}

.content{
	overflow-y: hidden;
	flex-grow: 1;
	margin-left: 12em;
}

@media only screen and (max-width: 930px) {
	.sidebar{
		display: none;
	}
	.content{
		margin-left: 0;
	}
}

.App-header {
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	margin: 20px;
}

.dashboard-content{
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	min-height: 100%;
	margin: 16px;
	margin-top: 0px;
}

.logo{
	width: 32em;
	margin: 2em;
}
