@font-face {
    font-family: 'proxima_novaregular';
    src: url('_.175-webfont.woff2') format('woff2'),
         url('_.175-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novabold';
    src: url('_.139-webfont.woff2') format('woff2'),
         url('_.139-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima_novasemibold';
    src: url('_.173-webfont.woff2') format('woff2'),
         url('_.173-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novasemibold';
    src: url('_.173-webfont.woff2') format('woff2'),
         url('_.173-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'proxima_novalight';
    src: url('_.5474-webfont.woff2') format('woff2'),
         url('_.5474-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}